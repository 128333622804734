import React, { useState, useEffect } from "react";
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { calcAssessmentAverageScore } from '../../includes/utilities';


const GroupReportBarChart = ({selectedStudents, selectedPeriods, selectedSubject}) => {

    const [labels, setLabels] = useState([]);
    const [datasets, setDatasets] = useState([]);

    useEffect(() => {

        if (!selectedStudents || selectedStudents.length < 1) {
            return;
        }

        const newLabels = [];
        let newDatasets = [];

        const barColourArray = [];

        const purpleGrad = (context) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 350);
            gradient.addColorStop(0, "rgba(127, 67, 217, 1)");
            gradient.addColorStop(1, "rgba(127, 67, 217, 0.4)");
            return gradient;
        }

        const greenGrad = (context) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 350);
            gradient.addColorStop(0, "rgba(53, 158, 89, 1)");
            gradient.addColorStop(1, "rgba(53, 158, 89, 0.4)");
            return gradient;
        }

        // for each selected period
        selectedStudents.forEach((student, i) => {

            // collect the labels (student names)
            newLabels.push(student.name);

            // baseline
            let existingBaselineSet = null;

            newDatasets.forEach((dataset) => {
                if (dataset.label === 'Baseline') {
                    existingBaselineSet = dataset;
                }
            });

            if (existingBaselineSet) {
                existingBaselineSet.data.push(calcAssessmentAverageScore(student.baseline_assessment, selectedSubject.subject));
            }

            // else create new one
            else {
                newDatasets.push({
                    label: 'Baseline',
                    data: [calcAssessmentAverageScore(student.baseline_assessment, selectedSubject.subject)],
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 350);
                        gradient.addColorStop(0, "rgba(100, 100, 100, 1)");
                        gradient.addColorStop(1, "rgba(100, 100, 100, 0.4)");
                        return gradient;
                    },
                    borderColor: 'rgba(60, 80, 99, 0.3)',
                    borderWidth: 1,
                    borderRadius: 6
                });
            }

            // order selected periods
            const orderedSelectedPeriods = selectedPeriods.sort((a, b) => {
                if (a.period_name === 'Baseline') {
                    return -1;
                }
    
                return a.id - b.id;
            });

            orderedSelectedPeriods.forEach((period, z) => {
                const thePeriod = student.pupil_periods.find((pupilPeriod) => {
                    return pupilPeriod.period_name === period.period_name && pupilPeriod.calendar_year === period.calendar_year;
                });

                let assessmentAveragesScore;

                if (thePeriod) {
                    assessmentAveragesScore = calcAssessmentAverageScore(thePeriod.assessment, selectedSubject.subject);
                } else {
                    assessmentAveragesScore = 0;
                }

                // reuse existing dataset if it exists
                let reusedDataset = false;

                newDatasets.forEach((dataset, y) => {
                    const comparisonLabel = `${period.period_name} ${period.calendar_year}`;
                    if (dataset.label === comparisonLabel) {
                        newDatasets[y].data = [...newDatasets[y].data, assessmentAveragesScore];
                        reusedDataset = true;
                    }
                });

                // else create new one
                if (!reusedDataset) {   
                    newDatasets.push({
                        label: `${period.period_name} ${period.calendar_year}`,
                        data: [assessmentAveragesScore],
                        backgroundColor: greenGrad,
                        borderColor: 'rgba(60, 80, 99, 0.3)',
                        borderWidth: 1,
                        borderRadius: 6
                    });
                }
            });
        });

        // add everages to labels and datasets at the beginning of the array
        newLabels.unshift('Average');
        newDatasets.forEach((dataset) => {
            let total = 0;
            let dataPoints = 0;
            dataset.data.forEach((level) => {
                if (level !== 0) {
                    dataPoints++;
                    total += level;
                }
            });
            const average = total / dataPoints;
            dataset.data.unshift(average);
        });

        setLabels(newLabels);
        setDatasets(newDatasets);

    }, [selectedStudents, selectedPeriods])


    return (
        <>
            {labels && datasets && 
                <Bar
                    data={{
                        labels: labels,
                        datasets: datasets
                    }}
                    options={{
                        scales: {
                            y: {
                                max: 6,
                                title: {
                                    display: true,
                                    text: 'Level'
                                }
                            },
                            x: {
                                ticks: {
                                  maxRotation: 50,
                                  minRotation: 30,
                                  padding: 10,
                                  autoSkip: false,
                                  fontSize: 10
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: true
                            }
                        }
                    }}
                />
            }
        </>
    )
}

export default GroupReportBarChart;